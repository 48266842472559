/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_METHOD, constants } from '../../constants';

interface AxiosStateOptions {
  type: API_METHOD;
  url: string;
  body?: any;
  headers?: AxiosRequestConfig['headers'];
  mimeType?: string;
  responseType?: string;
}

const ApiCall = async ({
  type,
  body,
  url,
  mimeType,
  responseType,
  ...headers
}: AxiosStateOptions): Promise<AxiosResponse<any>> => {
  const getToken = () => {
    return localStorage.getItem(constants.TOKEN);
  };
  const getHeader = () => {
    const customHeader: any = {};

    // add token
    const token = getToken();
    if (token) {
      customHeader['Authorization'] = `Bearer ${getToken()}`;
    }

    // add mimetype
    if (mimeType) {
      customHeader['Content-Type'] = mimeType;
    }

    return customHeader;
  };

  const getResponseType = () => {
    if (responseType) {
      return responseType;
    }
    return undefined; // Returning undefined if no responseType is provided
  };

  const axiosInstance = axios.create({
    baseURL: constants.BASE_API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      ...getHeader(),
      ...headers.headers,
    },
  });

  axiosInstance.interceptors.request.use((config: any) => {
    config.responseType = getResponseType();
    return config;
  });

  const finalUrl = () => {
    if (API_METHOD.isGET(type) || API_METHOD.isDELETE(type)) {
      const finalUrl = url;

      if (body === undefined) {
        return finalUrl;
      }

      const objString = new URLSearchParams(body).toString();

      return finalUrl + '?' + objString;
    } else {
      return url;
    }
  };

  let response: AxiosResponse<any>;

  try {
    switch (type) {
      case API_METHOD.POST:
        response = await axiosInstance.post(finalUrl(), body);
        break;
      case API_METHOD.PUT:
        response = await axiosInstance.put(finalUrl(), body);
        break;
      case API_METHOD.PATCH:
        response = await axiosInstance.patch(finalUrl(), body);
        break;
      case API_METHOD.DELETE:
        response = await axiosInstance.delete(finalUrl());
        break;
      default:
        response = await axiosInstance.get(finalUrl());
    }
    return response;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      // DO ANY ERROR HANDLING HERE
    }

    throw error;
  }
};

export default ApiCall;

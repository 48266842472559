/* Dashboard page header all select options */
export interface ISelectFilterOptions {
  label: string;
  value: string;
}

/* Robot all ads */
export interface IRobotAds {
  Ad_Binary: string;
  Ad_ID: number;
  Ad_type_Id: string;
  ad_impression: string;
  adtracker: string[];
  addomains: string[];
  //all_time_filter: string[];
  all_time_filter_min: string;
  all_time_filter_max: string;
  alltime_impression: string;
  impression_cat_1: string;
  impression_cat_2: string;
  impression_cat_3: string;
  impression_cat_4: string;
  session_geolocation: string[];
  session_os: string[];
  //time_filter: string[];
  time_filter_min: string;
  time_filter_max: string;
  zone_id: string[];
}

/* Admin session data */
export interface IAdminSession {
  Accept_Language_Header: string;
  Session_Browser: string;
  Session_Browser_language: string;
  Session_Carrier: string;
  Session_Device: string;
  Session_Geolocation: string;
  Session_OS: string;
  Session_end: string;
  Session_start: string;
  User_Agent_Header: string;
  id: number;
  user_IP_address: string;
}

/* Ad detail data */
export interface IAdGeoLangCombo {
  Ad_ID: number;
  Ad_Binary: string;
  Ad_type_Id: string;
  impression: number;
  Ad_Text: string;
  brand_text: string;
  Session_Browser_language: string;
  Session_Geolocation: string;
}

export interface IAdTargetDomains {
  ad_ID: number;
  impression: number;
  Session_Browser: string;
  Session_Geolocation: string;
  Redirection_URL: string;
}

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
  RND = 'rnd',
}
export enum MFATYPE {
  SMS = 'sms',
  APP = 'authenticator',
}
export enum USERSTATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  REJECTED = 'rejected',
}
export interface SessionDataType {
  token?: string;
  user?: UserType;
}
export interface UserType {
  id: string;
  username: string;
  phone: string;
  status: USERSTATUS;
  mfa_type: MFATYPE;
  isEmailVerified: boolean;
  isMFAVerified: boolean;
  updateEnabled: boolean;
  role: Role;
}
export interface UpdateUserPayload {
  status?: USERSTATUS;
  mfa_type?: MFATYPE | null;
  isMFAVerified?: boolean;
  role?: Role;
}
export interface SignUpPayload {
  username: string;
  phone: string;
  password: string;
}
export interface SignInPayload {
  username: string;
  password: string;
}
export interface ForgotPayload {
  username: string;
}
export interface AllAdsQueryParam {
  GEO: string;
  OS: string;
  tracker: string;
  zoneType: string;
  timeFilterLatestValue: number;
  timeFilterEarliestValue: number;
  siteURL: string;
  sortBy: string;
  offset: number;
}

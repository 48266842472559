import React from 'react';
import { AuthProvider } from './src/handlers/authProvider';
import { AlertProvider } from './src/handlers/alertProvider';

export function wrapPageElement({ element, props }) {
  return (
    <>
      <title>Dr.Knoww</title>
      <AlertProvider>
        <AuthProvider {...props}>{element}</AuthProvider>
      </AlertProvider>
    </>
  );
}

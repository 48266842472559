import React, { useState, useRef, useEffect } from 'react';
import { Button, Nav } from 'react-bootstrap';
import { useAuthContext } from '../handlers/authProvider';
import { Role } from '../utils/interfaces';
import * as commonStyles from '../styles/common.module.css';
import { CgCloseO, CgMenuRound } from 'react-icons/cg';

export default function SidebarNav() {
  const { user } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={sidebarRef}
      className={commonStyles.navContainer}
      style={{
        width: isOpen ? '200px' : '0px',
      }}
    >
      <Nav className="flex-column">
        <Button variant="link" className={commonStyles.navToggle} onClick={toggleSidebar}>
          {isOpen ? (
            <CgCloseO className={commonStyles.navTogglesvg} size={32} fill="white" />
          ) : (
            <CgMenuRound className={commonStyles.navTogglesvg} size={32} fill="white" />
          )}
        </Button>
        {isOpen && (
          <>
            <Nav.Link className={commonStyles.navLink} href="/dashboard">
              Home
            </Nav.Link>
            <Nav.Link className={commonStyles.navLink} href="/profile">
              Profile
            </Nav.Link>
            {user && (user.role === Role.ADMIN || user.role === Role.RND) && (
              <Nav.Link href="/admin_users" className={commonStyles.navLink}>
                Manage Users
              </Nav.Link>
            )}
            {user && (user.role === Role.ADMIN || user.role === Role.RND) && (
              <Nav.Link href="/admin_cog" className={commonStyles.navLink}>
                Manage Countries
              </Nav.Link>
            )}
          </>
        )}
      </Nav>
    </div>
  );
}

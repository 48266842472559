// extracted by mini-css-extract-plugin
export var buttonsDiv = "common-module--buttonsDiv--ad5d6";
export var cursorPointer = "common-module--cursorPointer--28a15";
export var customDropDownMenuDiv = "common-module--customDropDownMenuDiv--363d5";
export var filterDiv = "common-module--filterDiv--84e25";
export var filterRow = "common-module--filterRow--f9af5";
export var head = "common-module--head--dd2df";
export var headerContainer = "common-module--headerContainer--9ec29";
export var main = "common-module--main--e0677";
export var multiSelectCustom = "common-module--multiSelectCustom--045c7";
export var navContainer = "common-module--navContainer--c85f7";
export var navLink = "common-module--navLink--caf6a";
export var navToggle = "common-module--navToggle--e4fc1";
export var navTogglesvg = "common-module--navTogglesvg--7cb7e";
export var noDataText = "common-module--noDataText--2350c";
export var otpInput = "common-module--otpInput--9283c";
export var paginationDiv = "common-module--paginationDiv--a083d";
export var pt70 = "common-module--pt70--f71d6";
export var smsLink = "common-module--smsLink--807f9";
export var spin = "common-module--spin--fde2c";
export var spinner = "common-module--spinner--a688f";
export var textAlignCenter = "common-module--textAlignCenter--51efe";
export const constants = {
  HOME_PAGINATION_DATA: 40,
  TIMER_START_DAY: 0,
  TIMER_END_DAY: 730,
  BASE_API_URL: process.env.GATSBY_API_BASE_URL,
  ROBOT_ADS_API: 'allRobotAds',
  ROBOT_TRACKER_API: 'allRobotTrackers',
  ROBOT_AD_BY_ID_API: 'robotAdById',
  AD_DOWNLOAD: 'downloadAd',
  CREATE_JOB: 'createJob',
  ROBOT_ALL_GEOS: 'allGeos',
  ROBOT_UPDATE_GEOS: 'updateGeos',
  ROBOT_COUNTRIES_API: 'allCountries',
  ROBOT_SESSION_DATA_API: 'allSessionData',
  FIREBASE_DASHBOARD: 'firebaseDashboard',
  ROBOT_AD_SIZE_API: 'allAdDataZones',
  DASH: 'dash',
  TOKEN: 'temptoken',
};

export enum API_METHOD {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
}
export namespace API_METHOD {
  export function isGET(method: API_METHOD): boolean {
    return method === API_METHOD.GET;
  }

  export function isPOST(method: API_METHOD): boolean {
    return method === API_METHOD.POST;
  }

  export function isPUT(method: API_METHOD): boolean {
    return method === API_METHOD.PUT;
  }

  export function isPATCH(method: API_METHOD): boolean {
    return method === API_METHOD.PATCH;
  }

  export function isDELETE(method: API_METHOD): boolean {
    return method === API_METHOD.DELETE;
  }
}

import { API_METHOD } from '../../constants';
import ApiCall from './middleware';
import { AllAdsQueryParam, SignInPayload, SignUpPayload, UpdateUserPayload } from '../../utils/interfaces';
// AUTHENTICATION
export async function SignOutAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `auth/signout` });
}
export async function SignInAPI(data: SignInPayload) {
  return ApiCall({ type: API_METHOD.POST, url: `auth/signin`, body: data });
}
export async function VerifyEmail(data: { token: string }) {
  return ApiCall({ type: API_METHOD.POST, url: `auth/verify-email`, body: data });
}
export async function ForgotPasswordAPI(data: { username: string }) {
  return ApiCall({ type: API_METHOD.POST, url: `auth/forgot-password`, body: data });
}
export async function ResetPasswordAPI(data: { password: string; token: string }) {
  return ApiCall({ type: API_METHOD.POST, url: `auth/reset-password`, body: data });
}
// export async function ResendVerificationEmail(data: { email: string }) {
//   return ApiCall({ type: API_METHOD.POST, url: `auth/resend-email`, body: data });
// }
export async function SignUpAPI(data: SignUpPayload) {
  return ApiCall({ type: API_METHOD.POST, url: `auth/signup`, body: data });
}

export async function SendOTPAPI() {
  return ApiCall({ type: API_METHOD.POST, url: `auth/send-otp` });
}
export async function VerifyOTPAPI(otp: string) {
  return ApiCall({ type: API_METHOD.POST, url: `auth/verify-otp`, body: { otp } });
}
export async function Get2FAQR() {
  return ApiCall({ type: API_METHOD.GET, url: `auth/get-qr` });
}
export async function Verify2FAQR(token: string) {
  return ApiCall({ type: API_METHOD.POST, url: `auth/verify-qr`, body: { token } });
}

//USER
export async function GetUserAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `user` });
}
export async function GetAllUsersAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `user/all` });
}
export async function UpdateUserAPI(id: string, data: UpdateUserPayload) {
  return ApiCall({ type: API_METHOD.PUT, url: `user/${id}`, body: data });
}
export async function DelteUserAPI(id: string) {
  return ApiCall({ type: API_METHOD.DELETE, url: `user/${id}` });
}

//APP
export async function AllAdsAPI(body: AllAdsQueryParam) {
  return ApiCall({ type: API_METHOD.GET, url: `allRobotAds`, body });
}
export async function AdByIdAPI(body: {
  adId: number;
  timeFilterLatestValue: number;
  timeFilterEarliestValue: number;
}) {
  return ApiCall({ type: API_METHOD.GET, url: `robotAdById`, body });
}
export async function DownloadAdAPI(body: { url: string; country: string }) {
  return ApiCall({ type: API_METHOD.GET, url: `downloadAd`, body, responseType: 'blob' });
}
export async function UpdateGeoAPI(body: { sites: Array<any> }) {
  return ApiCall({ type: API_METHOD.PUT, url: `updateGeos`, body });
}

//COMMON
export async function AllTrackersAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `allRobotTrackers` });
}
export async function AllGeosAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `allGeos` });
}
export async function AllCrawlingSitesAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `allcrawlingsites` });
}
export async function AllCountriesAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `allCountries` });
}
export async function AllSessionsAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `allSessionData` });
}
export async function AllAdZonesAPI() {
  return ApiCall({ type: API_METHOD.GET, url: `allAdDataZones` });
}

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';

type AlertType = 'success' | 'error';

interface Alert {
  id: number;
  type: AlertType;
  message: string;
}

interface AlertContextProps {
  showAlert: (message: string, type: AlertType) => void;
  getConfirmation: (data: ConfirmProps) => void;
}

const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {},
  getConfirmation: () => {},
});

export const useAlert = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

type ConfirmProps = {
  title: string;
  message: string;
  onConfirm: () => void;
};
export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmData, setConfirmData] = useState<ConfirmProps | undefined>({
    title: 'Are you sure?',
    message: 'This action is irreversible',
    onConfirm: () => setShowConfirmModal(false),
  });

  const showAlert = (message: string, type: AlertType) => {
    const id = new Date().getTime();
    setAlerts(prev => [...prev, { id, type, message }]);

    setTimeout(() => {
      setAlerts(prev => prev.filter(alert => alert.id !== id));
    }, 5000);
  };

  const getConfirmation = (data: ConfirmProps) => {
    setConfirmData(data);
    setShowConfirmModal(true);
  };

  return (
    <AlertContext.Provider value={{ showAlert, getConfirmation }}>
      {children}
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
        {alerts.map(alert => (
          <Alert
            key={alert.id}
            variant={alert.type === 'success' ? 'success' : 'danger'}
            onClose={() => setAlerts(prev => prev.filter(a => a.id !== alert.id))}
            dismissible
          >
            {alert.message}
          </Alert>
        ))}
      </div>
      <Modal show={showConfirmModal} style={{ zIndex: 1056 }} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{confirmData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmData?.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={e => {
              e.preventDefault();
              confirmData?.onConfirm();
              setShowConfirmModal(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </AlertContext.Provider>
  );
};

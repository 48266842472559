import * as React from 'react';
import { useEffect, useState, useContext, createContext } from 'react';
import { useLocation } from '@reach/router';

import SidebarNav from '../components/sidebar';
import { USERSTATUS, UserType } from '../utils/interfaces';
import { GetUserAPI, SignOutAPI } from './API/services';

type IContext = {
  loading: boolean;
  user: UserType | null;
  tempToken: string | null;
  setTempToken: React.Dispatch<React.SetStateAction<string | null>>;
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  logout: () => void;
};

const AuthContext = createContext<IContext>({
  loading: true,
  user: null,
  tempToken: null,
  setTempToken: () => {},
  setUser: () => {},
  logout: () => {},
});

function isPagePublic(children: React.ReactNode): boolean {
  if (
    children &&
    typeof children === 'object' &&
    'type' in children &&
    children.type &&
    typeof children.type === 'function'
  ) {
    const _isPagePublic: boolean = Boolean(
      'isPagePublic' in children.type && (children.type as { isPagePublic: boolean }).isPagePublic
    );

    const _isDev404Page: boolean = Boolean(
      'name' in children.type && (children.type as unknown as { name: string }).name === 'Dev404Page'
    );

    return _isPagePublic || _isDev404Page;
  } else {
    return false;
  }
}
interface AuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { pathname } = useLocation();
  const [tempToken, setTempToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  async function getUser() {
    try {
      const result = await GetUserAPI();
      if (result && result.data && result.data.success) {
        setUser(result.data.user);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  async function removeSession() {
    await SignOutAPI();
    setUser(null);
  }

  useEffect(() => {
    if (!user) {
      setLoading(true);
      getUser();
    }
    return () => {};
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        setUser,
        user,
        tempToken,
        setTempToken,
        logout: removeSession,
      }}
    >
      {!loading &&
        !isPagePublic(children) &&
        !pathname.includes('/ad/') &&
        user &&
        user.status === USERSTATUS.ACTIVE && <SidebarNav />}
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  return useContext(AuthContext);
}
